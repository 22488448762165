// Settings
// -------------------------
$grid-columns: 12;

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px);

$spacer: 1rem;
$spacers: (0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 2.5),
  7: ($spacer * 3),
  8: ($spacer * 4),
  9: ($spacer * 5),
  10: ($spacer * 7.5),
  11: ($spacer * 10),
  12: ($spacer * 12.5),
);

// Brand colors
// -------------------------

$color-default: #666;
$color-primary: #fecb0c;
$color-secondary: #9f9f9f;
$color-tertiary: #000000;

$color-black: #000000;
$color-white: #ffffff;

$interrupter-color: $color-primary;

$contur-dark: #e9481d;
$contur-medium: #f05c20;
$contur: #ff8d2c;
$contur-light: #ff9d31;

$grey-darker: #242424;
$body: #575e62;
$grey-dark: #b4b4b4;
$grey-medium: #c1c1c1;
$grey-light: #f5f5f5;
$white: #ffffff;

// bootstrap variables override
$primary: $color-primary;
$secondary: $color-secondary;

// text

$headings-margin-bottom: $spacer;

// utilities
$utilities: ("heading": (property: font-size,
    class: heading,
    responsive: true,
    values: (
      7xl: 7.5rem,
      6xl: 5.625rem,
      5xl: 5.5rem,
      4xl: 5rem,
      3xl: 4.5rem,
      2xxl: 4.375rem,
      xxl: 4rem,
      xl: 3.5rem,
      2lg: 3.125rem,
      lg: 3rem,
      md: 2.5rem,
      sm: 2rem,
      xs: 1.5rem,
      xxs: 1.25rem,
      3xs: 1.125rem,
      4xs: 1rem,
      5xs: 0.875rem,
      6xs: 0.75rem,
    ),
  ),
  "main-heading": (property: font-size,
    class: main-heading,
    responsive: true,
    values: (
      xl: 11.25rem,
      lg: 8rem,
      md: 5rem,
      sm: 3.75rem,
      headerV2MediumMd: 4.25rem,
      headerV2MediumXl: 5.75rem,
    ),
  ),
  "body": (property: font-size,
    class: body,
    responsive: true,
    values: (5xl: 3.125rem,
      4xl: 2.25rem,
      3xl: 2rem,
      3xxl: 1.875rem,
      2xxl: 1.75rem,
      xxl: 1.5rem,
      xl: 1.25rem,
      lg: 1.125rem,
      md: 1rem,
      sm: 0.875rem,
      xs: 0.75rem,
    ),
  ),
  "height": (property: height,
    class: h,
    responsive: true,
    values: (25: 25%,
      50: 50%,
      60: 60%,
      70: 70%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
  "width": (property: width,
    class: w,
    responsive: true,
    values: (25: 25%,
      40: 40%,
      50: 50%,
      60: 60%,
      75: 75%,
      80: 80%,
      100: 100%,
      auto: auto,
    ),
  ),
  "max-width": (property: max-width,
    class: mw,
    values: (25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      initial: initial,
    ),
  ),
  "hyphens": (property: hyphens,
    class: hyphens,
    responsive: true,
    values: (none: none,
      manual: manual,
      auto: auto,
    ),
  ),
  "fw": (property: font-weight,
    class: fw,
    responsive: false,
    values: (medium: 500,
    ),
  ),
  "font": (property: font-family,
    class: font,
    responsive: false,
    values: (raleway: (Raleway, sans-serif),
      staatliches: (Staatliches, sans-serif),
    ),
  ),
);

$line-height-paragraph: 1.2;
